import styled from "styled-components";
import Images from "src/theme/Images";

// H2
export const PageTitle = styled.div`
  text-align: ${(props) => props.align};
  font-size: ${(props) => props["size"] || 24}px;
  line-height: 1.2;
  font-family: "Prata", serif;
  margin-bottom: 1.5rem;
  color: ${(props) => props["color"] || "black"};

  ${(props) => {
    if (!props["lines"]) {
      return null;
    }
    let fontSize = props["size"] || 24;
    let lineHeight = props["lineHeight"] || 1.2;
    let lines = props["lines"] || 1;
    let height = fontSize * lineHeight * lines - 0.5;
    return `
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      max-height: ${height}px;
      overflow: hidden;
      text-overflow: ellipsis;
      `;
  }}
`;

// H3
export const H3 = styled.div`
  font-size: ${(props) => props["size"] || 24}px;
  font-family: "Prata", serif;
  color: black;
`;

// H4-B
export const H4B = styled.h3`
  background-color: #000000;
  color: white;
  width: 100%;
  padding: 5px 10px 5px 10px;
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
  font-size: ${(props) => props["size"] || 20}px;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Assistant", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.4};
  position: relative;

  a {
    display: flex;
    align-items: center;
    color: white !important;

    span {
      line-height: 1;
    }
  }

  &.mt-1 {
    margin-top: 4px !important;
  }
  &.mt-2 {
    margin-top: 8px !important;
  }
  &.mb-1 {
    margin-bottom: 4px !important;
  }
  &.mb-2 {
    margin-bottom: 8px !important;
  }
`;

// H4
export const H4 = styled.div`
  font-size: ${(props) => props["size"] || 20}px;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Assistant", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.4};
  color: black;
`;

// H4-Sec
export const H4Sec = styled.div``;

// HF4
export const HF4 = styled.div`
  font-size: ${(props) => props["size"] || 18}px;
  letter-spacing: ${(props) => props["spacing"] || 0.2}rem;
  font-family: "Futura LT light";
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.4};
`;

// H5
export const H5 = styled.div`
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Prata", serif;
  font-size: ${(props) => props["size"] || 16}px;
  display: inline-block;
  text-align: left;
  color: black;
`;

// H6
export const H6 = styled.div`
  font-size: ${(props) => props["size"] || 14}px;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Assistant", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  line-height: ${(props) => props["lineHeight"] || 1.2};
  margin-top: 0.5rem !important;
  color: black;
  ${(props) => {
    if (!props["lines"]) {
      return null;
    }
    let fontSize = props["size"] || 14;
    let lineHeight = props["lineHeight"] || 1.2;
    let lines = props["lines"] || 1;
    let height = fontSize * lineHeight * lines;
    return `
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    max-height: ${height}px;
    overflow: hidden;
    text-overflow: ellipsis;
    `;
  }}
`;

// H7
export const H7 = styled.div`
  font-size: ${(props) => props["size"] || 11}px;
  font-weight: 600;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Assistant", sans-serif;
  line-height: ${(props) => props["lineHeight"] || 1.2};
  color: black;
  ${(props) => {
    if (!props["lines"]) {
      return null;
    }
    let fontSize = props["size"] || 11;
    let lineHeight = props["lineHeight"] || 1.2;
    let lines = props["lines"] || 1;
    let height = fontSize * lineHeight * lines;
    return (
      `
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      max-height: ${height}px;
      overflow: hidden;
      text-overflow: ellipsis;
      ` + (lines === 1 ? `white-space: nowrap;` : "")
    );
  }}
`;

// H8
export const H8 = styled.div`
  font-size: ${(props) => props["size"] || 14}px;
  letter-spacing: ${(props) => props["spacing"] || 0.1}rem;
  font-family: "Assistant", sans-serif;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  color: ${(props) => props["color"] || "black"};
  font-weight: 400;

  &:hover {
    font-weight: 600;
  }
`;

//H9
export const H9 = styled.div`
  font-size: ${(props) => props["size"] || 12}px;
  letter-spacing: ${(props) => props["spacing"] || 0.1}rem;
  font-family: "Assistant", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  color: black;
  line-height: 1;

  &:hover {
    font-weight: 600;
  }
`;

// Option Toggler
export const Toggler = styled.div.attrs((props) => ({
  className: "drop-toggler",
}))`
  font-size: ${(props) => props["size"] || 12}px;
  letter-spacing: ${(props) => props["spacing"] || 0.1}rem;
  font-family: "Assistant", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  color: black;
  line-height: 1;
  display: flex;
  align-items: center;
  padding-left: 4px;

  &::after {
    content: "";
    width: 10px;
    height: 6px;
    margin-left: 2px;
    border: none !important;
    background: url(${Images.carotIcon}) no-repeat 50%;
  }
`;

// H10 Bread title
export const Bread = styled.div`
  font-size: ${(props) => props["size"] || 13}px;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  font-family: "Assistant", sans-serif;
  line-height: ${(props) => props["lineHeight"] || 1.2};
  color: black;

  &.mb-0 {
    margin-bottom: 0px !important;
  }

  a {
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }
  }
  .current {
    color: #919191;
  }
`;

// H11
export const H11 = styled.div`
  font-size: ${(props) => props["size"] || 15}px;
  letter-spacing: ${(props) => props["spacing"] || 0.1}rem;
  font-family: "Assistant", sans-serif;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  text-transform: uppercase;
  color: black;
`;

// H14 G.ExtraInfoText Loading Statement
export const ExtraInfoText = styled.div`
  font-size: ${(props) => props["size"] || 16}px;
  font-family: "Prata", serif;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  color: ${(props) => props["color"] || "black"};
  text-align: center;
  font-weight: 400;
`;

// FLabel
export const FLabel = styled.label`
  font-size: ${(props) => props["size"] || 12}px;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  line-height: 1.6;
  font-family: "Assistant", sans-serif;
  color: black;
  ${(props) => {
    if (!props["lines"]) {
      return `height: 19px`;
    }
    let fontSize = props["size"] || 11;
    let lineHeight = props["lineHeight"] || 1.2;
    let lines = props["lines"] || 1;
    let height = fontSize * lineHeight * lines;
    return `
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      max-height: ${height}px;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 19px;
      `;
  }}
`;
// P1
export const Desc = styled.div`
  font-size: ${(props) => props["size"] || 14}px;
  letter-spacing: ${(props) => props["spacing"] || 0.0}rem;
  font-family: "Assistant", sans-serif;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  font-weight: 400;
  color: ${(props) => props["color"] || "black"};
`;
// P2
export const P2 = styled.div`
  font-size: ${(props) => props["size"] || 12}px;
  letter-spacing: ${(props) => props["spacing"] || 0.0}rem;
  font-family: "Assistant", sans-serif;
  line-height: ${(props) => props["lineHeight"] || 1.6};
  font-weight: 400;
  color: ${(props) => props["color"] || "black"};
`;
// P3
export const P3 = styled.div`
  font-size: ${(props) => props.size || 11}px;
  letter-spacing: ${(props) => props.spacing || 0.05}rem;
  font-family: "Assistant", sans-serif;
  line-height: 1.6;
  color: ${(props) => props["color"] || "black"};
`;

export const PageGuide = styled(Desc)`
  text-align: center;
  max-width: 440px;
  margin-top: 14px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export const Success = styled.div``;

export const Error = styled.div`
  font-size: 12px;
  color: red;
  border: ${(props) => (props["border"] ? 1 : 0)}px solid black;
  letter-spacing: ${(props) => props["spacing"] || 0.05}rem;
  line-height: 1.6;
  font-family: "Assistant", sans-serif;
  margin-top: 2px;
`;
