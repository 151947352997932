import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from './pages/SplashScreen';
// import { setUserData, logout } from 'src/actions/accountActions';
import authService from './services/auth.service';
import ACTIONS from './redux/actions';
import giftService from './services/gift.service';
import { SIGN_PAGES } from './constants/routes';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function initAuth() {
      authService.setAxiosInterceptors({
        onLogout: () => {
          console.log('logout'); // dispatch(logout())
          authService.logout();
          window.location.reload();
        }
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.restoreProfile();
        const configurations = await giftService.getConfigurations();

        await dispatch({
          type: ACTIONS.USER.SET,
          payload: user,
          configurations,
        });
      } else {
        if (SIGN_PAGES.indexOf(window.location.pathname) === -1) {
          // dispatch({
          //   type: ACTIONS.SIDEBAR.OPEN_SIGN,
          // });
        }
      }

      setLoading(false);
    }

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
