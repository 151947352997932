import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import SingleLayout from "./layouts/SingleLayout";
import GuestGuard from "./layouts/Guards/Guest.guard";
import AuthGuard from "./layouts/Guards/Auth.guard";
import LoadingScreen from "./pages/LoadingScreen";
import routes from "./constants/routes";
import Layout from "./layouts/Layout";
import EmptyLayout from "./layouts/EmptyLayout";
import ExtAuthGuard from "./layouts/Guards/ExtAuth.guide";

const routesConfig = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/",
    layout: Layout,
    component: lazy(() => import("./pages/Home")),
  },
  {
    exact: true,
    path: routes.ABOUT,
    layout: Layout,
    component: lazy(() => import("./pages/About")),
  },
  {
    exact: true,
    path: routes.PRIVACY_POLICY,
    layout: Layout,
    component: lazy(() => import("./pages/Privacy")),
  },
  {
    exact: true,
    path: routes.ERROR,
    layout: Layout,
    component: lazy(() => import("./pages/Error")),
  },
  {
    exact: true,
    path: routes.PLUGIN,
    layout: Layout,
    component: lazy(() => import("./pages/Plugin")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/log-in",
    component: lazy(() => import("./pages/Auth/Login")),
    layout: SingleLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.CREATE_ACCOUNT,
    component: lazy(() => import("./pages/Auth/Register")),
    layout: AuthLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.CREATE_ACCOUNT + "/:email",
    component: lazy(() => import("./pages/Auth/Register")),
    layout: AuthLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.FORGOT_PASSWORD,
    component: lazy(() => import("./pages/Auth/ForgotPassword")),
    layout: SingleLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/reset-password",
    component: lazy(() => import("./pages/Auth/ResetPassword")),
    layout: SingleLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/verify-email-request",
    component: lazy(() => import("./pages/Auth/VerifyEmailRequest")),
    layout: SingleLayout,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/verify-email",
    component: lazy(() => import("./pages/Auth/VerifyEmail")),
    layout: SingleLayout,
  },
  {
    exact: true,
    path: routes.INVITE,
    layout: Layout,
    component: lazy(() => import("./pages/Invite")),
  },
  {
    exact: true,
    path: routes.FEEDBACK,
    layout: Layout,
    component: lazy(() => import("./pages/Feedback")),
  },
  {
    exact: true,
    path: routes.DASHBOARD,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/MyProfile")),
  },
  {
    exact: true,
    path: routes.WISH_LIST,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/WishItems/ListPage")),
  },
  {
    exact: true,
    path: routes.ADD_WISH_ITEM,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/WishItems/CreatePage")),
  },
  {
    exact: true,
    path: routes.ADD_WISH_ITEM_WIDGET,
    guard: ExtAuthGuard,
    layout: EmptyLayout,
    component: lazy(() => import("./pages/WishItems/WidgetPage")),
  },
  {
    exact: true,
    path: routes.WISH_ITEM,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/WishItems/UpdatePage")),
  },
  {
    exact: true,
    path: routes.OTHER_WISH_ITEM,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/WishItems/DetailPage")),
  },

  {
    exact: true,
    path: routes.ACCOUNT_SETTING,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/AccountSetting")),
  },
  {
    exact: true,
    path: routes.NOTIFICATION_PREFERENCE,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/NotificationSetting")),
  },
  {
    exact: true,
    path: routes.UPDATE_PROFILE,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/UpdateProfile")),
  },
  {
    exact: true,
    path: routes.USER_DETAIL,
    // guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/UserDetail")),
  },

  // GIFT Related
  {
    exact: true,
    path: routes.GIFTGUIDES,
    layout: Layout,
    component: lazy(() => import("./pages/GiftGuides/ListPage")),
  },
  {
    exact: true,
    path: routes.GIFTITEM,
    layout: Layout,
    component: lazy(() => import("./pages/GiftGuides/DetailPage")),
  },

  // Friends Page
  {
    exact: true,
    path: routes.FRIENDS,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Friends/ListPage")),
  },
  {
    exact: true,
    path: routes.ALL_USERS,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/UserListPage")),
  },
  {
    exact: true,
    path: routes.USER_DETAIL,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/UserDetail")),
  },
  {
    exact: true,
    path: routes.USER_PROFILE,
    layout: Layout,
    component: lazy(() => import("./pages/Users/UserProfile")),
  },

  // Calendars Page
  {
    exact: true,
    path: routes.CALENDAR,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Calendars/ListPage")),
  },
  {
    exact: true,
    path: routes.SEARCH,
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/SearchPage")),
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || React.Fragment;
          const Layout = route.layout || React.Fragment;
          const Component = route.component;
          const isPublic = route.path === "/profile/:id" ? true : false;

          // const NewHoc = () => {
          //   return () => (
          //     <Guard>
          //       <Layout>
          //         {
          //           route.routes ? renderRoutes(route.routes) : <Component />
          //         }
          //       </Layout>
          //     </Guard>
          //   )
          // }

          // const Element = NewHoc();

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                   {/* <Element/> */}
                </Layout>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </Suspense>
  ) : null;

function Navigators() {
  return renderRoutes(routesConfig);
}

export default Navigators;
