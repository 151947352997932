import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import routes from "src/constants/routes";
import Images from "src/theme/Images";
import * as G from "src/theme/Styled";
import * as S from "./styled";
import * as Typos from "src/components/atoms/Typographs";
import ACTIONS from "src/redux/actions";
import authService from "src/services/auth.service";
import LOGINACTION from "src/redux/actions";

const Header = (props) => {
  const user = useSelector((state) => state.users?.user);
  const closeMenuTime = useSelector((state) => state.sidebar?.closeMenuTime);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasSearchForm, setSearchForm] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [keyword, setKeyword] = useState("");

  const toggleSearchForm = () => {
    setSearchForm(!hasSearchForm);
  };

  const onChangeKeyword = (evt) => {
    setKeyword(evt.target.value);
  };

  const onKeyInspect = (evt) => {
    if (evt.key === "Enter") {
      if (keyword) {
        navigate(routes.SEARCH + "?keyword=" + keyword);
        setSearchForm(false);
      }
    }
  };

  const _renderSearchForm = () => {
    return (
      <>
        {hasSearchForm ? (
          <S.SearchForm>
            <Typos.FLabel>Enter Criteria To Search</Typos.FLabel>
            <input
              type="text"
              value={keyword}
              onChange={onChangeKeyword}
              onKeyDown={onKeyInspect}
            />
          </S.SearchForm>
        ) : null}
        <S.SearchIcon
          src={Images.searchIcon}
          alt="search"
          onClick={toggleSearchForm}
        ></S.SearchIcon>
      </>
    );
  };

  const toggleMenu = () => {
    setOpenMenu(!isOpenMenu);
  };

  const logout = () => {
    authService.logout();
    navigate("/");
    window.location.reload();
  };

  const openGuide = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_GUIDE,
    });
  };

  const openContact = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_CONTACT,
    });
  };

  useEffect(() => {
    if (closeMenuTime) {
      setOpenMenu(false);
    }
  }, [closeMenuTime]);

  const openSign = () => {
    dispatch({
      type: LOGINACTION.SIDEBAR.OPEN_SIGN,
    });
  };

  const changeUser = (userId) => {
    let key = "accessToken_" + userId;

    authService.setCurrentSession(key);
    window.location.reload();
  };

  return (
    <S.Header className="authHeader-ext">
      <G.Container>
        <S.TitleBar className="d-flex v-center">
          <S.MainActions className="d-flex vh-center ml-auto">
            <UncontrolledDropdown className="clear-dropdown">
              <DropdownToggle>
                {user?.avatar ? (
                  <S.Avatar src={user?.avatar} alt="user"></S.Avatar>
                ) : (
                  <S.Avatar src={Images.account_icon} alt="user"></S.Avatar>
                )}
              </DropdownToggle>
              <DropdownMenu className="account-menu account-menu-menu">
                {authService.getLoginUsers().map((item) => (
                  <DropdownItem className="login-account-users">
                    <Typos.H9 style={{ textTransform: "capitalize" }}>
                      <div
                        onClick={() => changeUser(item.userId)}
                        className="inner-connect"
                      >
                        <S.Avatar src={item.avatar} alt="user"></S.Avatar>{" "}
                        {item.fullName}
                      </div>
                    </Typos.H9>
                  </DropdownItem>
                ))}
                <DropdownItem>
                  <Typos.H9 style={{ textTransform: "capitalize" }}>
                    <div onClick={openSign}>
                      <S.PlusIconSmall>+</S.PlusIconSmall> Add Account
                    </div>
                  </Typos.H9>
                </DropdownItem>
                <DropdownItem onClick={logout}>
                  <Typos.H9
                    style={{ textTransform: "capitalize", lineHeight: 1.2 }}
                  >
                    Log Out
                  </Typos.H9>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </S.MainActions>
        </S.TitleBar>
      </G.Container>
    </S.Header>
  );
};

export default Header;
