export const SIDEBAR = {
    SIGN: 'SIGN_SIDEBAR',
    CONTACT: 'CONTACT_SIDEBAR',
    GUIDE: 'GUIDE_SIDEBAR'
}

export const PRICE_POINTS = [
    {
        title: 'Under $25',
        id: '25',
        low: 0,
        up: 25
    },
    {
        title: '$25-$50',
        id: '50',
        low: 25,
        up: 50
    },
    {
        title: '$50-$100',
        id: '100',
        low: 50,
        up: 100
    },
    {
        title: '$100-$250',
        id: '250',
        low: 100,
        up: 250
    },
    {
        title: '$250+',
        id: '250+',
        low: 250
    }
];