import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import * as Buttons from 'src/components/atoms/Buttons';
import ACTIONS from "src/redux/actions";
import Images from "src/theme/Images";
import * as Typos from 'src/components/atoms/Typographs';

import * as S from './styled';
import links from "src/constants/links";
import { CONTACT_BUTTON } from "src/theme/Labels";
import userService from "src/services/user.service";
import { useState } from "react";
import { Status } from "src/config";

const Contact = () => {
    const dispatch = useDispatch();
    const [ submitStatus, setSubmitStatus ] = useState(Status.NONE);
    const { register, handleSubmit, formState: { errors } } = useForm();


    const closeSidebar = () => {
        dispatch({
            type: ACTIONS.SIDEBAR.CLOSE
        });
    };

    const onSubmit = data => {
        setSubmitStatus(Status.REQUEST);
        userService.contact(data)
            .then(() => {
                setSubmitStatus(Status.SUCCESS);
                closeSidebar();
            })
            .catch(() => {
                setSubmitStatus(Status.FAILURE);
            });
    }

    return (
        <S.Container theme='black'>
            <S.CloseIcon src={Images.whiteCloseIcon} alt="close" onClick={closeSidebar}></S.CloseIcon>
            <S.Content className="h-100">
                <S.ContactPanel className="h-100 c-flex">
                    <S.Form>
                        <Typos.PageTitle className="text-white text-center">Contact Us</Typos.PageTitle>
                        <Typos.Desc className="text-white text-center">
                            We'd love to hear from you! Submit questions, comments, feature requests or feedback here.
                        </Typos.Desc>
                        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-2">
                                <Typos.FLabel className="text-white">*Name</Typos.FLabel>
                                <input type="text" 
                                    {
                                        ...register('name', {
                                            required: {
                                                value: true,
                                                message: 'Name is required'
                                            }
                                        })
                                    }
                                    className={errors.name ? 'has-error' : ''}
                                />
                                {errors.name ? <Typos.Error className="">{errors.name.message}</Typos.Error> : null}
                            </div>
                            <div className="mb-2">
                                <Typos.FLabel className="text-white">*Email</Typos.FLabel>
                                <input
                                    {
                                        ...register('email', {
                                            required: {
                                                value: true,
                                                message: 'Email is required'
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address"
                                            }
                                        })
                                    }
                                    className={errors.email ? 'has-error' : ''}
                                />
                                {errors.email ? <Typos.Error className="">{errors.email.message}</Typos.Error> : null}
                            </div>
                            <div>
                                <Typos.FLabel className="text-white">Message</Typos.FLabel>
                                <textarea
                                    className="d-block w-100"
                                    rows="5"
                                    style={{minHeight: '100px'}}
                                    {
                                        ...register('message')
                                    }
                                >
                                </textarea>
                            </div>
                            <Buttons.Submit className='d-block w-100 mt-3' title={CONTACT_BUTTON} status={submitStatus} theme="light" />
                        </form>
                    </S.Form>
                    <S.SocialIcons className="mt-auto">
                        <S.Icon as="a" target="_blank" rel="noreferrer" href={links.PINTEREST}>
                            <img src={Images.whitePinterest} alt=""/>
                        </S.Icon>
                        <S.Icon as="a" target="_blank" rel="noreferrer" href={links.INSTAGRAM}>
                            <img src={Images.whiteInstagram} alt=""/>
                        </S.Icon>
                        <S.Icon as="a" target="_blank" rel="noreferrer" href={links.FACEBOOK}>
                            <img src={Images.whiteFacebook} alt=""/>
                        </S.Icon>
                        <S.Icon as="a" target="_blank" rel="noreferrer" href={links.TWITTER}>
                            <img src={Images.whiteTwitter} alt=""/>
                        </S.Icon>
                        <S.Icon as="a" target="_blank" rel="noreferrer" href={links.YOUTUBE}>
                            <img src={Images.whiteYoutube} alt=""/>
                        </S.Icon>
                    </S.SocialIcons>
                </S.ContactPanel>
            </S.Content>
        </S.Container>
    )

};

export default Contact;