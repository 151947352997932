// import jwtDecode from 'jwt-decode';
import { API_BASE_URL } from "src/config";
import { post, update, get, remove } from "src/utils/rest";
import sanity from "src/utils/sanity";
import ENDPOINTS from "src/constants/endpoints";
import { resHandler, errorHandler } from "src/utils/functions";

const giftQuery = `*[_type == "gift-guides" {%filter%} ] {
    _id,
    title,
    price,
    slug,
    url,
    mainImage,
    pricePoint,
    retailer,
    body,
    trending,
    demo,
    ageGroups
  } | order(_createdAt desc)
  `;
const pricePointQuery = `*[_type == "pricePoint"] {
_id,
title
} | order(_createdAt asc)
`;
const ageGroupQuery = `*[_type == "ageGroups"] {
_id,
title,
enable
} | order(_createdAt)
`;
const demoQuery = `*[_type == "demo"] {
_id,
title,
enable
}
`;
const interestQuery = `*[_type == "interests"] {
_id,
title
}
`;
const eventQuery = `*[_type == "lifeEvents"] {
_id,
title
}
`;

const retailerQuery = `*[_type == "retailer"] {
_id,
title
}
`;

class GiftService {
  getConfigurations = async () => {
    let pricePoints = await sanity.fetch(pricePointQuery);
    let ageGroups = await sanity.fetch(ageGroupQuery);
    let demos = await sanity.fetch(demoQuery);
    let interests = await sanity.fetch(interestQuery);
    let events = await sanity.fetch(eventQuery);
    let retailers = await sanity.fetch(retailerQuery);

    return {
      pricePoints,
      ageGroups,
      demos,
      interests,
      events,
      retailers,
    };
  };
  load = (skip, limit, filterQuery) =>
    new Promise((resolve, reject) => {
      try {
        let query = giftQuery;
        if (filterQuery) {
          query = query.replace(/\{\%filter\%\}/gi, `&& ${filterQuery}`);
        } else {
          query = query.replace(/\{\%filter\%\}/gi, "");
        }
        query = query + `[${skip}...${skip + limit}]`;
        sanity
          .fetch(query)
          .then((data) => {
            resHandler(resolve, reject, { status: true, data });
          })
          .catch((err) => {
            errorHandler(reject, err);
          });
      } catch (error) {
        errorHandler(reject, error);
      }
    });
  loadRecommend = (skip, limit, filterQuery) =>
    new Promise((resolve, reject) => {
      try {
        let query = giftQuery;
        if (filterQuery) {
          query = query.replace(/\{\%filter\%\}/gi, `&& ${filterQuery}`);
        } else {
          query = query.replace(/\{\%filter\%\}/gi, "");
        }
        query = query + `[${skip}...${skip + limit}]`;
        sanity
          .fetch(query)
          .then((data) => {
            resHandler(resolve, reject, { status: true, data });
          })
          .catch((err) => {
            errorHandler(reject, err);
          });
      } catch (error) {
        errorHandler(reject, error);
      }
    });
}

const giftService = new GiftService();

export default giftService;
