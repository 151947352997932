// import jwtDecode from 'jwt-decode';
import axios from "src/utils/axios";
import { API_BASE_URL } from "src/config";
import { post } from "src/utils/rest";
import { errorHandler, resHandler } from "src/utils/functions";

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      post(`${API_BASE_URL}/user/login`, { email, password }, false)
        .then((data) => {
          if (data.data?.token) {
            let loginUsers = localStorage.getItem("loginUsers");

            if (!loginUsers) {
              loginUsers = [];
            } else {
              loginUsers = JSON.parse(loginUsers);
            }

            let users = {
              userId: data.data.user._id,
              fullName: data.data.user.firstname + " " + data.data.user.lastname,
              avatar: data.data.user.avatar,
            };

            loginUsers.push(users);

            this.setSession(JSON.stringify(loginUsers), "loginUsers");

            let key = "accessToken_" + data.data?.user._id;
            this.setSession(data.data?.token, key);
            this.setCurrentSession(key);
          }
          resHandler(resolve, reject, data);
        })
        .catch((err) => {
          errorHandler(reject, err);
        });
    });

  restoreProfile = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/user/me`)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken, key = "accessToken") => {
    if (accessToken) {
      localStorage.setItem(key, accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setCurrentSession = (key) => {
    localStorage.setItem("currentKey", key);
  };

  getAccessToken = () =>
    localStorage.getItem(localStorage.getItem("currentKey"));

  getLoginUsers = () => JSON.parse(localStorage.getItem("loginUsers"));

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    // const decoded = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;

    // return decoded.exp > currentTime;
    return true;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
