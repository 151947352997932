import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const sanity = sanityClient({
  projectId: "4vl9bacd",
  dataset: "production",
  useCdn: true
});

const imageBuilder = imageUrlBuilder(sanity);
export function imageUrlFor(source) {
  return imageBuilder.image(source);
}

export default sanity;