import { useDispatch, useSelector } from 'react-redux';

import Sign from 'src/pages/Sidebar/Sign';
import Guide from 'src/pages/Sidebar/Guide';
import Contact from 'src/pages/Sidebar/Contact';

import * as S from './styled';
import ACTIONS from 'src/redux/actions';
import { SIDEBAR } from 'src/constants';
import React, { useEffect } from 'react';

const COMPONENTS = {
    [SIDEBAR.SIGN]: Sign,
    [SIDEBAR.GUIDE]: Guide,
    [SIDEBAR.CONTACT]: Contact,
}

const Sidebar = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.sidebar);

    const closeSidebar = () => {
        dispatch({
            type: ACTIONS.SIDEBAR.CLOSE
        });
    };

    useEffect(() => {
        if (data.isOpened) {
            if (data.type === SIDEBAR.GUIDE) {
                document.body.classList.add('overflow-hidden');
            }
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [data.isOpened]);

    const Component = COMPONENTS[data.type] || React.Fragment;
    return <>
    {
        data?.isOpened ? <S.BackDrop onClick={closeSidebar}/> : null
    }
        <S.Container opened={data?.isOpened}>
            <Component />
        </S.Container>
    </>
}

export default Sidebar;