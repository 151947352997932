import Header from "src/components/templates/header";
import Footer from "src/components/templates/footer";
import { AuthPage, Body, Container, MainPage } from "src/theme/Styled";
import { useSelector } from "react-redux";

const Layout = ({children}) => {
  const user = useSelector(state => state.users?.user);

  return <Body>
    { !user ? <Header.Guest /> : <Header.Auth />}
    {
        user ? (
            <MainPage>
                <Container className="page">
                    {children}
                </Container>
            </MainPage>
        ) : (
            <AuthPage>
                <Container className="page">
                    {children}
                </Container>
            </AuthPage>
        )
    }
    <Footer />
  </Body>
};

export default Layout;