export const INVITE_BUTTON = {
  NONE: "INVITE",
  REQUEST: "INVITING",
  FAILURE: "INVITE",
  SUCCESS: "INVITE SENT",
};

export const CONTACT_BUTTON = {
  NONE: "SUBMIT",
  REQUEST: "SUBMITTING",
  FAILURE: "SUBMIT FAILED",
  SUCCESS: "SUBMITTED",
};

export const SIGN_CHECK_BUTTON = {
  NONE: "NEXT",
  REQUEST: "CHECKING",
  FAILURE: "NEXT",
  SUCCESS: "NEXT",
};

export const LOGIN_CHECK_BUTTON = {
  NONE: "NEXT",
  REQUEST: "CHECKING",
  FAILURE: "NEXT",
  SUCCESS: "NEXT",
};
export const LOGIN_SIGN_BUTTON = {
  NONE: "SIGN IN",
  REQUEST: "SIGNING IN",
  FAILURE: "SIGN FAILED",
  SUCCESS: "SIGNED SUCCESSFULLY",
};

export const SIGN_BUTTON = {
  NONE: "SIGN IN",
  REQUEST: "SIGNING IN",
  FAILURE: "SIGN IN",
  SUCCESS: "SIGNED IN SUCCESSFULLY",
};

export const REGISER_BUTTON = {
  NONE: "CREATE ACCOUNT",
  REQUEST: "CREATING ACCOUNT",
  FAILURE: "CREATE ACCOUNT",
  SUCCESS: "CREATED SUCCESSFULLY",
};

export const START_BUTTON = {
  NONE: "GET STARTED",
  REQUEST: "GETTING STARTED",
  FAILURE: "GET STARTED",
  SUCCESS: "GET STARTED",
};

export const FEED_BUTTON = {
  NONE: "SUBMIT",
  REQUEST: "SUBMITTING",
  FAILURE: "SUBMIT",
  SUCCESS: "SUBMITTED",
};

export const ADD_WISH_ITEM_BUTTON = {
  NONE: "ADD TO WISH LIST",
  REQUEST: "ADDING TO WISH LIST",
  FAILURE: "ADD TO WISH LIST",
  SUCCESS: "ADDED TO WISH LIST",
};

export const ACCOUNT_SETTING_BUTTON = {
  NONE: "SAVE",
  REQUEST: "SAVING",
  FAILURE: "SAVE",
  SUCCESS: "SAVE",
};

export const UPDATE_WISH_ITEM_BUTTON = {
  NONE: "SAVE",
  REQUEST: "SAVING",
  FAILURE: "SAVING FAILED",
  SUCCESS: "SAVED",
};

export const UNPURCHASE_WISH_ITEM_BUTTON = {
  NONE: "UPDATE ITEM AS NOT PURCHASED",
  REQUEST: "UPDATING",
  FAILURE: "UPDATING FAILED",
  SUCCESS: "UPDATED AS NOT PURCHASED",
};

export const PURCHASE_WISH_ITEM_BUTTON = {
  NONE: "UPDATE ITEM AS PURCHASED",
  REQUEST: "UPDATING",
  FAILURE: "UPDATING FAILED",
  SUCCESS: "UPDATED AS PURCHASED",
};

export const REMOVE_WISH_ITEM_BUTTON = {
  NONE: "REMOVE FROM WISH LIST",
  REQUEST: "REMOVING FROM WISH LIST",
  FAILURE: "ADD TO WISH LIST",
  SUCCESS: "ADDED TO WISH LIST",
};

export const EVENT_CREATE_BUTTON = {
  NONE: "CREATE",
  REQUEST: "CREATING",
  FAILURE: "FAILED",
  SUCCESS: "CREATED",
};

export const EVENT_UPDATE_BUTTON = {
  NONE: "SAVE",
  REQUEST: "SAVING",
  FAILURE: "FAILED",
  SUCCESS: "SAVED",
};

export const EVENT_DELETE_BUTTON = {
  NONE: "DELETE",
  REQUEST: "DELETING",
  FAILURE: "FAILED",
  SUCCESS: "DELETED",
};

export const PROFILE_UPDATE_BUTTON = {
  NONE: "SAVE",
  REQUEST: "SAVING",
  FAILURE: "SAVE",
  SUCCESS: "SAVED",
};

export const FOLLOW_BUTTON = {
  NONE: "FOLLOW",
  REQUEST: "FOLLOWING",
  FAILURE: "FAILED",
  SUCCESS: "UNFOLLOW",
};

export const UNFOLLOW_BUTTON = {
  NONE: "UNFOLLOW",
  REQUEST: "UNFOLLOWING",
  FAILURE: "FAILED",
  SUCCESS: "FOLLOW",
};

export const FORGOT_PASSWORD_BUTTON = {
  NONE: "SEND",
  REQUEST: "SENDING",
  FAILURE: "SENDING FAILED",
  SUCCESS: "SENT SUCCESSFULLY",
};

export const RESET_PASSWORD_BUTTON = {
  NONE: "RESET PASSWORD",
  REQUEST: "RESETTING PASSWORD",
  FAILURE: "RESET PASSWORD",
  SUCCESS: "RESET SUCCESSFULLY",
};

export const VERIFY_REQUEST = {
  NONE: "SEND",
  REQUEST: "SENDING",
  FAILURE: "SENDING FAILED",
  SUCCESS: "SENT SUCCESSFULLY",
};

export const EMAIL_VERIFY_BUTTON = {
  NONE: "VERIFY EMAIL",
  REQUEST: "VERIFYING EMAIL",
  FAILURE: "VERIFY FAILED",
  SUCCESS: "VERIFY SUCCESSFULLY",
};

export const GIFT_ADD_BUTTON = {
  NONE: "ADD TO WISH LIST",
  REQUEST: "ADDING TO WISH LIST",
  FAILURE: "ADDING FAILED",
  SUCCESS: "ADDED TO WISH LIST",
};

export const SHARE_BUTTON = {
  NONE: "SEND",
  REQUEST: "SENDING",
  FAILURE: "SENDING FAILED",
  SUCCESS: "SENT SUCCESSFULLY",
};

export const LOGIN_NAVIGATE_BUTTON = {
  NONE: "LOG IN",
  REQUEST: "LOG IN",
  FAILURE: "LOG IN",
  SUCCESS: "LOG IN",
};
