import styled from 'styled-components';
import Images from './Images';

export const Body = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 1012px;
    padding-left: 16px;
    padding-right: 16px;
    margin: auto;

    &.page {
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;
export const SmallContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin: auto;
`;
export const AuthPage = styled.div`
    padding-top:175px;

    &.single {
        flex: 1;
        height: 100%;
        display: flex;
        width: 100%;
        padding-top: 0px;
    }
`;
export const MainPage = styled.div`
    padding-top: 175px;

    @media screen and (max-width: 680px) {
        padding-top: 140px;
    }
`
export const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #0004;
`;


export const Btn = styled.button.attrs(props => ({
    className: (props.theme ? props.theme + '-button' : '') + (props.size ? ' size-' + props.size : '')
}))`
    font-size: ${props => props.style?.fontSize || 15}px;
    height: ${props => props.style?.height || 40}px;
    letter-spacing: ${props => props.style?.spacing || 0.05}rem;
    display: block;
    font-family: 'Assistant', sans-serif;
    font-weight: 300;
    background-color: black;
    color: white;
    border: none;
    outline: none!important;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        background: #2b2b2b;
        color: white;
    }

    &.disabled-button {
        background: gainsboro !important;
        border-color: gainsboro !important;
    }

    &.light-button {
        background: white;
        color: black;

        &:hover {
            background: #2b2b2b;
            color: white;
        }
    }

    &.outline-button {
        border: 1px solid black;
        background: white;
        color: black;

        &:hover {
            background: rgb(242, 242, 242) !important;
        }
    }

    &.no-border-button {
        border-color: transparent;
        background: transparent;
        color: black;
    }

    &.fit-width {
        width: unset!important;
    }

    &.with-label {
        margin-top: 21px;
    }

    img {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

    &.size-small {
        width: unset;
        min-width: 80px;
        height: 28px;
        margin: auto;        
    }
`

// Button 3
export const LinkButton = styled.a`
  text-decoration: underline!important;
  font-size: ${props => props['size'] || 13}px;
  letter-spacing: ${props => props['spacing'] || 0.05}rem;
  display: block;
  cursor: pointer;
  font-family: 'Assistant', sans-serif;
  color: black;

  &:hover {
    font-weight:600!important;
  }
  &:disabeled {
    color: #C7C7C7;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;
  z-index: 1;

  &.with-label {
      margin-top: 21px;
  }
  &.form-checkbox {
      height: 40px;
      align-items: center;
  }

  input {
    width: unset;
    opacity: 0;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0px;
    top: 50%;
    transform: translateY(-9px);
    z-index: 2;
  }
  label {
      display: inline-block;
      z-index: 1;

      &::before {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border: 1px solid #919191;
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-9px);
      }
  }

  input:checked ~ label::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-9px);
    background-image: url(${Images.checkIcon});
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
  }

  input:checked ~ label::before {
      border-color: black;
  }

  input:hover ~ label::before {
    border-color: black;
  }

  &.placeholder {
    opacity: 0.5;
    pointer-events: none;
    background: transparent;
  }
`;