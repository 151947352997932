import styled from 'styled-components';
export const BackDrop = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #0004;
    z-index: 1001;
`

export const Container = styled.div.attrs(props => ({
    className: props.opened ? 'opened' : '',
}))`
    position: fixed;
    bottom: 0px;
    top: 0px;
    min-width: 320px;
    max-width: 400px;
    width: 100%;
    right: 0px;
    background-color: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 1002;

    &.opened {
        transform: translateX(0);    
    }
`