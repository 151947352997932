import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import ACTIONS from "./redux/actions";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const [ prevPath, setPrevPath] = useState('');
    const dispatch = useDispatch();
  
    useEffect(() => {
        if (!pathname === '/giftguides') {
            window.scrollTo(0, 0);
        } else {
            if (prevPath.includes('/giftguides/')) {
                const giftId = prevPath.replace('/giftguides/', '');
                setTimeout(() => {
                    const giftDom = document.querySelector('#gift-item-' + giftId);
                    giftDom && giftDom.scrollIntoView({block: 'end'});
                }, 200)
            } else {
                window.scrollTo(0, 0);
            }
        }
        dispatch({
            type: ACTIONS.SIDEBAR.CLOSE_MENU
        });
        dispatch({
            type: ACTIONS.SIDEBAR.CLOSE
        });
        setPrevPath(pathname);
    }, [pathname]);
  
    return null;
  }