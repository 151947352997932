const ACTIONS = {
  SET: 'USER.SET',
  SET_CONFIG: 'USER.SET_CONFIG',

  LOGIN: 'USER.LOGIN',
  LOGIN_REQUEST: 'USER.LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER.LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER.LOGIN_FAILURE',

  CHECK_EMAIL: 'USER.CHECK_EMAIL',
  CHECK_EMAIL_REQUEST: 'USER.CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'USER.CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE: 'USER.CHECK_EMAIL_FAILURE',

  REGISTER: 'USER.REGISTER',
  REGISTER_REQUEST: 'USER.REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER.REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER.REGISTER_FAILURE',
  
  UPDATE: 'USER.UPDATE',
  UPDATE_REQUEST: 'USER.UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER.UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER.UPDATE_FAILURE',

  FORGOT_PASSWORD: 'USER.FORGOT_PASSWORD',
  FORGOT_PASSWORD_REQUEST: 'USER.FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'USER.FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'USER.FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD: 'USER.RESET_PASSWORD',
  RESET_PASSWORD_REQUEST: 'USER.RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER.RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USER.RESET_PASSWORD_FAILURE',

  LOAD_USER: 'USER.LOAD',
  LOAD_USER_REQUEST: 'USER.LOAD_REQUEST',
  LOAD_USER_SUCCESS: 'USER.LOAD_SUCCESS',
  LOAD_USER_FAILURE: 'USER.LOAD_FAILURE',

  REQUEST_VERIFY_EMAIL: 'USER.REQUEST_VERIFY_EMAIL',
  REQUEST_VERIFY_EMAIL_REQUEST: 'USER.REQUEST_VERIFY_EMAIL_REQUEST',
  REQUEST_VERIFY_EMAIL_SUCCESS: 'USER.REQUEST_VERIFY_EMAIL_SUCCESS',
  REQUEST_VERIFY_EMAIL_FAILURE: 'USER.REQUEST_VERIFY_EMAIL_FAILURE',

  VERIFY_EMAIL: 'USER.VERIFY_EMAIL',
  VERIFY_EMAIL_REQUEST: 'USER.VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'USER.VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'USER.VERIFY_EMAIL_FAILURE',

  REQUEST_VERIFY_PHONE: 'USER.REQUEST_VERIFY_PHONE',
  REQUEST_VERIFY_PHONE_REQUEST: 'USER.REQUEST_VERIFY_PHONE_REQUEST',
  REQUEST_VERIFY_PHONE_SUCCESS: 'USER.REQUEST_VERIFY_PHONE_SUCCESS',
  REQUEST_VERIFY_PHONE_FAILURE: 'USER.REQUEST_VERIFY_PHONE_FAILURE',

  VERIFY_PHONE: 'USER.VERIFY_PHONE',
  VERIFY_PHONE_REQUEST: 'USER.VERIFY_PHONE_REQUEST',
  VERIFY_PHONE_SUCCESS: 'USER.VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_FAILURE: 'USER.VERIFY_PHONE_FAILURE',

  SET_EVENTS: 'USER.SET_EVENTS',
  CREATE_EVENT: 'USER.CREATE_EVENT',
  UPDATE_EVENT: 'USER.UPDATE_EVENT',
  REMOVE_EVENT: 'USER.REMOVE_EVENT',

  SET_FRIENDS: 'USER.SET_FRIENDS',
  ADD_FRIEND: 'USER.CREATE_FRIEND',
  UPDATE_FRIEND: 'USER.UPDATE_FRIEND',
  REMOVE_FRIEND: 'USER.REMOVE_FRIEND',

  SET_WISHITEMS: 'USER.SET_WISHITEMS',
  CREATE_WISHITEM: 'USER.CREATE_WISHITEM',
  UPDATE_WISHITEM: 'USER.UPDATE_WISHITEM',
  REMOVE_WISHITEM: 'USER.REMOVE_WISHITEM',

  SET_FOLLOWEES: 'USER.SET_FOLLOWEES',
  SET_GIFTS: 'USER.SET_GIFTS',

};

export default ACTIONS;