import account_icon from 'src/assets/images/img_173956.png';
import whitePinterest from 'src/assets/images/pinterest-white.svg';
import whiteTwitter from 'src/assets/images/twitter-white.svg';
import whiteFacebook from 'src/assets/images/facebook-white.svg';
import whiteInstagram from 'src/assets/images/instagram-white.svg';
import whiteYoutube from 'src/assets/images/youtube-white.svg';
import plusIcon from 'src/assets/images/plus.png';
import closeIcon from 'src/assets/images/close.png';
import whiteCloseIcon from 'src/assets/images/close-white.png';
import searchIcon from 'src/assets/images/search.svg';
import eyeIcon from 'src/assets/images/eye.png';
import eyeCloseIcon from 'src/assets/images/eye_close.png';
import pencilIcon from 'src/assets/images/pencil.svg';
import infoIcon from 'src/assets/images/information.svg';
import checkIcon from 'src/assets/images/check.png';
import carotIcon from 'src/assets/images/carot.svg';

import leftArrowIcon from 'src/assets/images/chevron_left.svg';
import rightArrowIcon from 'src/assets/images/chevron_right.svg';
import shareIcon from 'src/assets/images/sent.png';

import VideoPoster from 'src/assets/images/video_image.png';
import GoogleBadge from 'src/assets/images/google-play-badge.png';
import AppleBadge from 'src/assets/images/app_store_badge_us.svg';
import ChromBadge from 'src/assets/images/chrome_store_button.png';
import calendar_d from 'src/assets/images/calendar_d.png';
import wishlist_d from 'src/assets/images/wish list.png';
import reminder_d from 'src/assets/images/reminders.png';
import giftguide_d from 'src/assets/images/gift guides.png';

import StarIcon from 'src/assets/images/star.svg';
import EmptyStarIcon from 'src/assets/images/empty-star.svg';
import GiftIcon from 'src/assets/images/gift.png';

import EmailIcon from 'src/assets/images/email_iconfinder_mail.png';
import LinkIcon from 'src/assets/images/link_iconfinder_link.png';
import HeartIcon from 'src/assets/images/heart.png';

import RotateLeftIcon from 'src/assets/images/rotate_left.png';
import RotateRightIcon from 'src/assets/images/rotate_right.png';

import exWishImage1 from 'src/assets/images/ex/1.png';
import exWishImage2 from 'src/assets/images/ex/2.png';
import exWishImage3 from 'src/assets/images/ex/3.png';
import exWishImage4 from 'src/assets/images/ex/4.png';
import exWishImage5 from 'src/assets/images/ex/5.png';
import exUserImage1 from 'src/assets/images/ex/user1.jpg';
import exUserImage2 from 'src/assets/images/ex/user2.png';
import exUserImage3 from 'src/assets/images/ex/user3.png';
import exUserImage4 from 'src/assets/images/ex/user4.png';
import exUserImage5 from 'src/assets/images/ex/user5.png';
import exUserImage6 from 'src/assets/images/ex/user6.png';
import exProdImage1 from 'src/assets/images/ex/r_1.png';
import exProdImage2 from 'src/assets/images/ex/r_2.png';
import exProdImage3 from 'src/assets/images/ex/r_3.png';
import exProdImage4 from 'src/assets/images/ex/r_4.png';
import exProdImage5 from 'src/assets/images/ex/r_5.png';
import exProdImage6 from 'src/assets/images/ex/r_6.png';
import exProdImage7 from 'src/assets/images/ex/r_7.png';
import exFollowing1 from 'src/assets/images/ex/f_1.png';
import exFollowing2 from 'src/assets/images/ex/f_2.png';
import exFollowing3 from 'src/assets/images/ex/f_3.png';
import exFollowing4 from 'src/assets/images/ex/f_4.png';
import exFollowing5 from 'src/assets/images/ex/f_5.png';
import exFollowing6 from 'src/assets/images/ex/f_6.png';
import exFollowing7 from 'src/assets/images/ex/f_7.png';

const Images = {
    account_icon,
    whitePinterest,
    whiteTwitter,
    whiteFacebook,
    whiteInstagram,
    whiteYoutube,
    plusIcon,
    closeIcon,
    whiteCloseIcon,
    searchIcon,
    eyeIcon,
    eyeCloseIcon,
    pencilIcon,
    infoIcon,
    checkIcon,
    carotIcon,
    rightArrowIcon,
    leftArrowIcon,
    shareIcon,

    VideoPoster,
    AppleBadge,
    GoogleBadge,
    ChromBadge,
    calendar_d,
    wishlist_d,
    reminder_d,
    giftguide_d,
    
    StarIcon,
    EmptyStarIcon,
    GiftIcon,

    EmailIcon,
    LinkIcon,
    HeartIcon,

    RotateLeftIcon,
    RotateRightIcon,

    exWishImage1,
    exWishImage2, 
    exWishImage3,
    exWishImage4, 
    exWishImage5, 
    exUserImage1,     
    exUserImage2, // ArrowDownIcon: require('src/assets/images/down-arrow.png'),
    exUserImage3, // WhiteCloseIcon: require('src/assets/images/close-white.png'),
    exUserImage4, // YoutubeIcon: require('src/assets/images/youtube.png'),
    exUserImage5, // DefaultProduct: require('src/assets/images/gift.png'),
    exUserImage6, // DefaultUser: require('src/assets/images/profile.png'),
    exProdImage1, // DefaultProfile: require('src/assets/images/profile.png'),
    exProdImage2, // Facebook: require('src/assets/images/facebook.svg'),
    exProdImage3, // Facebook2: require('src/assets/images/facebook-1.svg'),
    exProdImage4, // Google: require('src/assets/images/google.svg'),
    exProdImage5, // Google2: require('src/assets/images/google-1.svg'),
    exProdImage6, // 
    exProdImage7, // 
    exFollowing1, // CalenderIcon: require('src/assets/images/calendar.png'),
    exFollowing2, // ReminderIcon: require('src/assets/images/watch.png'),
    exFollowing3, // 
    exFollowing4, // 
    exFollowing5, // ,
    exFollowing6, // RotateRightIcon: require('src/assets/images/rotate_right.png'),
    exFollowing7, // WhiteCalendar: require('src/assets/images/white-calendar.svg'),
};

export default Images;
// WhiteFavorite: require('src/assets/images/white-heart.svg'),
// WhiteSave: require('src/assets/images/white-rect.png'),
// WhiteAcSetting: require('src/assets/images/white-rect.png'),
// WhitePreferences: require('src/assets/images/white-preference.svg'),
// WhiteFriends: require('src/assets/images/white-friends.svg'),
// WhiteHeart: require('src/assets/images/white-heart.svg'),
// WhiteGift: require('src/assets/images/white-gift.svg'),
// logo: require('src/assets/images/logo.svg'),
// search: require('src/assets/images/search.svg'),
// friends: require('src/assets/images/friends.svg'),
// heart: require('src/assets/images/heart.svg'),
// close: require('src/assets/images/close.svg'),
// account: require('src/assets/images/account.svg'),
// setting: require('src/assets/images/setting.svg'),
// envelope: require('src/assets/images/envelope.svg'),
// advertise: require('src/assets/images/advertise.svg'),
// link: require('src/assets/images/link.svg'),
// calendarWeak: require('src/assets/images/calendar-weak.svg'),
// reminderWeak: require('src/assets/images/alarm-weak.svg'),
// giftWeak:  require('src/assets/images/gift-weak.svg'),
// quickStart: require('src/assets/images/Asset 1.svg'),
// startGuide: require('src/assets/images/Asset 2.svg'),
// help: require('src/assets/images/Asset 3.svg'),
// sent: require('src/assets/images/sent.png'),
// LoadMore: require('src/assets/images/carot.svg'),

// main_video: require('src/assets/images/video_image.png'),
// pauseIcon: require('src/assets/images/pause-button.svg'),
// playIcon: require('src/assets/images/play-button.svg'),
