// import jwtDecode from 'jwt-decode';
import { API_BASE_URL } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';
import { post, update, get, postWithCancel } from 'src/utils/rest';
import { errorHandler, resHandler } from 'src/utils/functions';
class UserService {
createAccount = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.REGISTER, data, false, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
});
forgotPassword = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.FORGOT_PASSWORD, data, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
});
resetPassword = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.RESET_PASSWORD, data, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
});
updateProfile = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.UPDATE, data, true, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
requestVerifyEmail = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.REQUEST_VERIFY_EMAIL, data, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
});
verifyEmail = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.VERIFY_EMAIL, data, false)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
});
requestVerifyPhone = data => new Promise((resolve, reject) => {

});
verifyPhone = data => new Promise((resolve, reject) => {

});
loadMyProfile = () => new Promise((resolve, reject) => {
  get(API_BASE_URL + ENDPOINTS.LOAD_DETAIL)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
loadOtherUser = username => new Promise((resolve, reject) => {
  get(API_BASE_URL + ENDPOINTS.LOAD_DETAIL + username)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
loadOtherProfile = username => new Promise((resolve, reject) => {
  get(API_BASE_URL + ENDPOINTS.LOAD_PROFILE + username)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
searchUser = data => new Promise((resolve, reject) => {
  postWithCancel('userSearch', API_BASE_URL + ENDPOINTS.SEARCH_USER, data)
    .then(data => {
      resHandler(resolve, reject, data?.data);
    })
    .catch(err => {
      errorHandler(reject, err);
    });
})
shareData = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.SHARE, data)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
contact = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.CONTACT, data)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
giveFeedback = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.FEEDBACK, data)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
invite = data => new Promise((resolve, reject) => {
  post(API_BASE_URL + ENDPOINTS.INVITE, data)
    .then(data => {
      resHandler(resolve, reject, data);
    })
    .catch(err => {
      errorHandler(reject, err);
    })
});
}

const userService = new UserService();

export default userService;
