import styled from 'styled-components';
import { H8, Desc } from 'src/components/atoms/Typographs';

export const Container = styled.div.attrs(props => ({
    className: props.theme ? props.theme : 'light',
}))`
    width: 100%;
    height: 100%;
    padding: 15px;
    overflow: auto;

    &.black {
        background-color: black;
    }
`

export const CloseIcon = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 14px;
    height: 14px;
    cursor: pointer;
`;

export const Content = styled.div`
    width: 100%;
    margin: auto
`;

//////////////////////////////////////////////////////////////
///////////////// Contact Panel //////////////////////////////
//////////////////////////////////////////////////////////////
export const ContactPanel = styled.div`
    padding-top: 5.5rem;
    max-width: 320px;
    margin: auto;
`;

export const Form = styled.div`
    padding-bottom: 1rem;
`;

export const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    justify-content: center;
`;

export const Icon = styled.a.attrs(props => ({
    className: 'vh-center'
}))`
    flex: 1;
    max-width: 36px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;

    img {
        width: 100%;
    }
`;

//////////////////////////////////////////////////////////////
///////////////// Sign Panel //////////////////////////////
//////////////////////////////////////////////////////////////
export const SignPanel = styled.div`
    padding: 0px 15px;
    
    .change-form-icon {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
`;

//////////////////////////////////////////////////////////////////////////////
///////////////////// Guide Panel ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export const GuidePanel = {
    Title: styled.div`
    text-align: center;
    padding: 24px 15px 0 px 15px 
    `,

    ProgressBar: styled.div`
    display: flex;
    padding: 0px 15px 15px 15px;
    justify-content: space-between;
    `,

    Progress: styled.div`
        width: 36px;
        height: 5px;
        border-radius: 3px;
        background-color: rgb(64,64,64);

        &.active {
            background-color: white;
        }
    `,

    Steps : styled.div``,

    Step : styled.div`
        .step-content {
            display: none;
        }

        &.active .step-title {
            color: white;
        }

        .step-index {
            color: white;
        }
        &.active .step-index {
            color: black;
            background-color: white;
            border-color: white;
        }

        &.active .step-content {
            display: block;
        }

        .step-title.done .step-index {
            color: transparent;
            user-select: none;

            &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 6px;
            border-bottom: 2px solid rgb(200, 200, 200);
            border-left: 2px solid rgb(200, 200, 200);
            top: 9px;
            left: 7px;
            transform: rotate(-45deg);
            }
        }

        &.last {
            border-bottom: 1px solid rgb(97, 97, 97);
        }

        a[href] {
            text-decoration: underline;
        }
    `,

    StepTitle : styled(H8).attrs({
            className: 'step-title d-block'
        })`
        border-top: 1px solid rgb(97, 97, 97);
        padding: 10px 8px 10px 44px;
        position: relative;
        cursor: pointer;
        color: rgb(200, 200, 200);

        .step-index {
            position: absolute;
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid rgb(145, 145, 145);
            left: 8px;
            text-align: center;
            line-height: 26px;
            top: 8px;
            padding-left: 2px;
        }
    `,

    StepContent : styled(Desc).attrs({
            className: 'step-content text-white'
        })`
        padding: 8px 16px 8px 40px;
        color: white;
        letter-spacing: 0.1rem;
        line-height: 1.8;
    `,

    Button : styled.a`
        display: block;
        border: 1px solid white;
        padding: 6px 12px;
        max-width: 250px;
        text-align: center;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        outline: none!important;
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 16px;
        color: white;
        :hover {
            background-color: #2B2B2B;
            border: 1px solid #FFFFFF;
            color: white;
        }
    `,

    Panel : styled.div.attrs({
            className: 'quick-panel started-guide'
        })`
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        max-width: 380px;
        background-color: black;
        color: white;
        z-index: 1001;
        height: 100vh;
        overflow: auto;
        transform: translateX(100%);
        transition: transform .4s;
        padding-top: 30px;

        &.active {
            transform: none;
        }
    `,

    PluginBtn : styled.a`
        background: white!important;
        color: black!important;
        margin-top: 12px!important;
        display: block;
        max-width: 245px;
        margin: auto;
    `,


    VideoWrapper : styled.div`

    video {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        border: none;  
    }

    img {
        position: absolute;
        height: 80px;
        width: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all .7s;

        &.active {
        opacity: 1;
        }
    }

    &:hover img {
        opacity: 1;
    }

    .video-react-big-play-button {
        border-radius: 50%!important;
        width: 80px;
        height: 80px;
        font-size: 72px;
        margin: 0px!important;
        transform: translate(-50%, -50%);
        padding: 0px!important;
        line-height: 1;
    }
    `
}