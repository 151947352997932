const ACTIONS = {
    CLOSE: 'SIDEBAR.CLOSE',
  
    OPEN_SIGN: 'SIDEBAR.SIGN',
    OPEN_CONTACT: 'SIDEBAR.CONTACT',
    OPEN_GUIDE: 'SIDEBAR.GUIDE',

    CLOSE_MENU: 'MENU.CLOSE'
  };
  
  export default ACTIONS;