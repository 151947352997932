/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
// import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import rootSaga from "./sagas";
const sagaMiddleware = createSagaMiddleware();
// import { ENABLE_REDUX_LOGGER } from '../config';

// const loggerMiddleware = createLogger();

// const middlewares = [thunkMiddleware];

// if (ENABLE_REDUX_LOGGER) {
//   middlewares.push(loggerMiddleware);
// }

// const middlewareEnhancer = composeWithDevTools(
//   applyMiddleware(...middlewares)
// );

// const enhancers = [middlewareEnhancer];
const enhancers = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  );
// const composedEnhancers = compose(...enhancers);

const store = createStore(rootReducer, enhancers);
sagaMiddleware.run(rootSaga);

export default store;