import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Auth from './Auth';
import Routes from './Routes';
import Sidebar from './partials/sidebar';
import './App.css';
import ScrollToTop from './ScrollToTop';

const history = createBrowserHistory();

history.listen((location, action) => {
  console.log(location, action);
})

function App() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Auth>
        <Routes />
        <Sidebar />
      </Auth>
    </Router>
  );
}

export default App;
