import Header from "src/components/templates/header";
import Footer from "src/components/templates/footer";
import { AuthPage, Body, Container } from "src/theme/Styled";


const AuthLayout = ({children}) => {
  return <Body>
    <Header.Guest />
    <AuthPage>
      <Container className="page">
        {children}
      </Container>
    </AuthPage>
    <Footer />
  </Body>
};

export default AuthLayout;