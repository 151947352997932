import { createReducer } from 'reduxsauce';
import { SIDEBAR } from 'src/constants';
import ACTIONS from 'src/redux/actions';
const initialState = {
  data: null,
  
  type: null,
  isOpened: false,

  closeMenuTime: null,
};

const closeSidebar = (state, action) => ({
    ...state,
    isOpened: false,
    type: null,
    data: null,
});

const openSign = (state, action) => ({
    ...state,
    data: action.payload,
    type: SIDEBAR.SIGN,
    isOpened: true,
});
const openGuide = (state, action) => ({
    ...state,
    data: action.payload,
    type: SIDEBAR.GUIDE,
    isOpened: true,
});
const openContact = (state, action) => ({
    ...state,
    data: action.payload,
    type: SIDEBAR.CONTACT,
    isOpened: true,
});

const closeMenu = (state) => ({
  ...state,
  closeMenuTime: Date.now()
});

const actionHandlers = {
  [ACTIONS.SIDEBAR.CLOSE]: closeSidebar,
  [ACTIONS.SIDEBAR.OPEN_SIGN]: openSign,
  [ACTIONS.SIDEBAR.OPEN_CONTACT]: openContact,
  [ACTIONS.SIDEBAR.OPEN_GUIDE]: openGuide,
  [ACTIONS.SIDEBAR.CLOSE_MENU]: closeMenu
};

export default createReducer(initialState, actionHandlers);
