import Header from "src/components/templates/header";
import Footer from "src/components/templates/footer";
import { AuthPage, Body, Container, MainPage } from "src/theme/Styled";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import * as Typos from "src/components/atoms/Typographs";
import { Submit } from "src/components/atoms/Buttons";
import { LOGIN_NAVIGATE_BUTTON } from "src/theme/Labels";

const MainLayout = ({ isPublic, children }) => {
  const user = useSelector((state) => state.users?.user);
  return (
    <Body>
      {!user ? <Header.Guest /> : <Header.Auth />}
      <MainPage>
        <Container className="page">
          {
            user || isPublic ? children : children
            // <Modal
            //   open={true}
            //   blockScroll={false}
            //   classNames={{
            //     root: "loginModalroot",
            //     overlay: 'loginModalOverlay husnez',
            //     modal: 'loginModal',
            //   }}>
            //   <Typos.PageTitle className="login_title">Oops, you need to login for that</Typos.PageTitle>
            //   <div className="login_description">In order to use that feature, you need to log in or create an account.</div>
            //   <div className="login_btn" >
            //     <Submit type="button" title={LOGIN_NAVIGATE_BUTTON} />
            //   </div>
            // </Modal>
          }
        </Container>
      </MainPage>
      <Footer />
    </Body>
  );
};

export default MainLayout;
