import { useSelector } from "react-redux"
import { Navigate } from 'react-router-dom';
import routes from "src/constants/routes";

const ExtAuthGuard = ({children}) => {
  const user = useSelector(state => state.users?.user);

  if (user) {
    return children;
  }

  return <Navigate to={routes.LOG_IN + '?nextPath=' + (routes.ADD_WISH_ITEM_WIDGET + new URL(window.location.href).search).replace(/&/g, '%26')} />;
}

export default ExtAuthGuard;