import { createReducer } from 'reduxsauce';
import { Status } from 'src/config';
import ACTIONS from 'src/redux/actions';
const initialState = {
  user: null,
  configurations: {},
  events: [],
  friends: [],
  followees: [],
  wishItems: [],
  gifts: [],

  giftWishes: [],
  friendIds: [],
  
  updateStatus: Status.NONE,
  checkEmailStatus: Status.NONE,
  loginStatus: Status.NONE,
  registerStatus: Status.NONE,
  loadStatus: Status.NONE,
  forgotPasswordStatus: Status.NONE,
  resetPasswordStatus: Status.NONE,
  requestVerifyEmailStatus: Status.NONE,
  verifyEmailStatus: Status.NONE,
  requestVerifyPhoneStatus: Status.NONE,
  verifyPhoneStatus: Status.NONE,

  errorMessage: ''
};

const setUser = (state, action) => ({
  ...state,
  user: action.payload,
  configurations: action.configurations,
  friends: action.payload.friends || []
});

const setConfigurations = (state, action) => ({
  ...state,
  configurations: action.payload
});

const updateUserRequest = state => ({
  ...state,
  updateStatus: Status.REQUEST,
});
const updateUserSuccess = (state, action) => {
  return {
    ...state,
    user: { ...state.user, ...action.payload},
    updateStatus: Status.SUCCESS,
  };
};
const updateUserFailure = (state, action) => ({
  ...state,
  updateStatus: Status.FAILURE,
  errorMessage: action.error
});

const checkEmailRequest = state => ({
  ...state,
  checkEmailStatus: Status.REQUEST,
});
const checkEmailSuccess = state => ({
  ...state,
  checkEmailStatus: Status.SUCCESS,
});
const checkEmailFailure = (state, action) => ({
  ...state,
  checkEmailStatus: Status.FAILURE,
  errorMessage: action.error,
});

const loginRequest = state => ({
  ...state,
  loginStatus: Status.REQUEST
});
const loginSuccess = (state, action) => {
  const { token, user, firstname, lastname } = action.payload;

  let loginUsers = localStorage.getItem("loginUsers")

  if(!loginUsers){
    loginUsers = [];
  }

  let users = {
    userId:  user._id,
    fullName:  firstname + ' ' + lastname,
    avatar: user.avatar,
  }

  loginUsers.push(users);

  let key = 'accessToken_' +  user._id
  localStorage.setItem(key, token);
  localStorage.setItem("loginUsers", loginUsers);
  localStorage.setItem("currentKey", key);
  return {
    ...state,
    loginStatus: Status.SUCCESS,
    user,
  };
};
const loginFailure = (state, action) => ({
  ...state,
  loginStatus: Status.FAILURE
});

const registerRequest = (state, action) => ({});
const registerSuccess = (state, action) => ({});
const registerFailure = (state, action) => ({});

const updateRequest = (state, action) => ({});
const updateSuccess = (state, action) => ({});
const updateFailure = (state, action) => ({});

const loadDetailRequest = (state, action) => ({});
const loadDetailSuccess = (state, action) => ({});
const loadDetailFailure = (state, action) => ({});

const forgotPasswordRequest = (state, action) => ({});
const forgotPasswordSuccess = (state, action) => ({});
const forgotPasswordFailure = (state, action) => ({});

const resetPasswordRequest = (state, action) => ({});
const resetPasswordSuccess = (state, action) => ({});
const resetPasswordFailure = (state, action) => ({});

const requestVerifyEmailRequest = (state, action) => ({});
const requestVerifyEmailSuccess = (state, action) => ({});
const requestVerifyEmailFailure = (state, action) => ({});

const verifyEmailRequest = (state, action) => ({});
const verifyEmailSuccess = (state, action) => ({});
const verifyEmailFailure = (state, action) => ({});

const requestVerifyPhoneRequest = (state, action) => ({});
const requestVerifyPhoneSuccess = (state, action) => ({});
const requestVerifyPhoneFailure = (state, action) => ({});

const verifyPhoneRequest = (state, action) => ({});
const verifyPhoneSuccess = (state, action) => ({});
const verifyPhoneFailure = (state, action) => ({});

const setEvents = (state, action) => ({
  ...state,
  events: action.payload || [],
});
const updateEvent = (state, action) => {
  const events = [...state.events];
  const id = action.id;
  const payload = action.payload;
  events.some((e, index) => {
    if (id === e._id) {
      events.splice(index, 1, {...e, ...payload});
      return true;
    }
  });
  return {
    ...state,
    events: [...events],
  };
};
const removeEvent = (state, action) => {
  const events = [...state.events];
  const id = action.id;
  events.some((e, index) => {
    if (id === e._id) {
      events.splice(index, 1);
      return true;
    }
  });

  return {
    ...state,
    events,
  };
};
const createEvent = (state, action) => {
  const events = [...state.events];
  events.push(action.payload);

  return {
    ...state,
    events,
  };
};
const setFriends = (state, action) => ({
  ...state,
  friends: action.payload || [],
});
const addFriend = (state, action) => {
  const friends = state.friends;
  const pos = friends.indexOf(action.payload);
  if (pos === -1) {
    friends.push(action.payload);
  }
  return {
    ...state,
    friends: [...friends]
  }
};
const removeFriend = (state, action) => {
  const friends = state.friends;
  const pos = friends.indexOf(action.payload);
  if (pos !== -1) {
    friends.splice(pos, 1);
  }
  return {
    ...state,
    friends: [...friends]
  }
};
const updateFriend = (state, action) => ({});
const setFollowees = (state, action) => ({
  ...state,
  followees: action.payload || [],
});
const setWishItems = (state, action) => ({
  ...state,
  wishItems: action.payload || [],
});
const createWishItem = (state, action) => {
  const wishItems = [...state.wishItems];
  const giftWishes = [...state.giftWishes];
  wishItems.push(action.payload);
  if (action.payload?.gift) {
    giftWishes.push(action.payload?.gift);
  }

  return {
    ...state,
    wishItems,
    giftWishes,
  };
};
const updateWishItem = (state, action) => {
  const wishItems = [...state.wishItems];
  const id = action.id;
  const payload = action.payload;
  wishItems.some((e, index) => {
    if (id === e._id) {
      wishItems.splice(index, 1, {...e, ...payload});
      return true;
    }
  });
  return {
    ...state,
    wishItems: [...wishItems],
  };
};
const deleteWishItem = (state, action) => {
  const wishItems = [...state.wishItems];
  const id = action.id;
  wishItems.some((e, index) => {
    if (id === e._id) {
      wishItems.splice(index, 1);
      return true;
    }
  });
  // TODO: remove from giftWishes

  return {
    ...state,
    wishItems,
  };
};
const setGifts = (state, action) => {
  return {
    ...state,
    gifts: action.payload
  };
};



const actionHandlers = {
  [ACTIONS.USER.SET]: setUser,
  [ACTIONS.USER.SET_CONFIG]: setConfigurations,

  [ACTIONS.USER.UPDATE_REQUEST]: updateUserRequest,
  [ACTIONS.USER.UPDATE_SUCCESS]: updateUserSuccess,
  [ACTIONS.USER.UPDATE_FAILURE]: updateUserFailure,

  [ACTIONS.USER.CHECK_EMAIL_REQUEST]: checkEmailRequest,
  [ACTIONS.USER.CHECK_EMAIL_FAILURE]: checkEmailFailure,
  [ACTIONS.USER.CHECK_EMAIL_SUCCESS]: checkEmailSuccess,

  [ACTIONS.USER.LOGIN_REQUEST]: loginRequest,
  [ACTIONS.USER.LOGIN_FAILURE]: loginFailure,
  [ACTIONS.USER.LOGIN_SUCCESS]: loginSuccess,

  [ACTIONS.USER.SET_EVENTS]: setEvents,
  [ACTIONS.USER.CREATE_EVENT]: createEvent,
  [ACTIONS.USER.UPDATE_EVENT]: updateEvent,
  [ACTIONS.USER.REMOVE_EVENT]: removeEvent,

  [ACTIONS.USER.SET_FRIENDS]: setFriends,
  [ACTIONS.USER.SET_FOLLOWEES]: setFollowees,
  [ACTIONS.USER.ADD_FRIEND]: addFriend,
  [ACTIONS.USER.REMOVE_FRIEND]: removeFriend,
  
  [ACTIONS.USER.SET_WISHITEMS]: setWishItems,
  [ACTIONS.USER.CREATE_WISHITEM]: createWishItem,
  [ACTIONS.USER.UPDATE_WISHITEM]: updateWishItem,
  [ACTIONS.USER.REMOVE_WISHITEM]: deleteWishItem,

  [ACTIONS.USER.SET_GIFTS]: setGifts
};

export default createReducer(initialState, actionHandlers);
