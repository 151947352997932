import { Status } from "src/config"
import { Btn } from 'src/theme/Styled';

export const Submit = (props) => {
    const handleClick = () => {
        const { onClick } = props;
        if (onClick) {
            onClick();
        }
    };
    return (
        <Btn
            type={props.type || 'submit'}
            disabled={props.status === Status.REQUEST || props.disabled}
            style={props.style}
            theme={props.theme}
            className={props.className}
            onClick={handleClick}
            form={props.id}
        >
            {props.title?.[props.status] || props.title?.[Status.NONE] }
        </Btn>
    )
}