import { useSelector } from "react-redux"
import { Navigate } from 'react-router-dom';

const AuthGuard = ({children}) => {
  const user = useSelector(state => state.users?.user);

  if (user) {
    return children;
  }

  return <Navigate to="/" />;
}

export default AuthGuard;