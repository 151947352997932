import React from 'react';
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom";
import routes from 'src/constants/routes';

const GuestGuard = ({children}) => {
  const user = useSelector(state => state.users?.user);

  if (user) {
    const nextPath = new URLSearchParams(window.location.search).get('nextPath');
    if (nextPath) {
      return <Navigate to={nextPath} />;
    }
    return <Navigate to={routes.DASHBOARD} />;
  }
  
  return children;
}

export default GuestGuard;