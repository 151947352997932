import Header from "src/components/templates/header";
import { AuthPage, Body, Container } from "src/theme/Styled";

const EmptyLayout = ({ children }) => {
  return (
    <Body>
      <Header.AuthExt />
      <AuthPage className="single">
        <Container className="page authHeader-ext-page">{children}</Container>
      </AuthPage>
    </Body>
  );
};

export default EmptyLayout;
