import Images from "src/theme/Images";
import { Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import * as Buttons from "src/components/atoms/Buttons";
import * as Typos from "src/components/atoms/Typographs";
import { INVITE_BUTTON } from "src/theme/Labels";
import * as G from "src/theme/Styled";
import * as S from "./styled";
import routes from "src/constants/routes";
import { useState } from "react";
import { Status } from "src/config";
import { useDispatch, useSelector } from "react-redux";
import ACTIONS from "src/redux/actions";
import links from "src/constants/links";
import userService from "src/services/user.service";
import { wrap } from "lodash";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users?.user);
  const [email, setEmail] = useState("");
  const [inviting, setInviting] = useState(Status.NONE);
  const [error, setError] = useState("");
  const openContact = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_CONTACT,
    });
  };

  const changeEmail = (evt) => {
    setError("");
    setEmail(evt.target.value);
  };

  const inviteFriend = (evt) => {
    evt.preventDefault();
    if (!user || !user._id) {
      navigate(routes.INVITE);
      return;
    }
    const phoneReg = /[0-9]{9}$/;
    const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}/gim;
    const data = {
      username: user.firstname + " " + user.lastname,
    };
    if (emailReg.test(email)) {
      data["invitee_email"] = email;
    } else {
      let phoneString = email.replace(/[()-\s]/g, "");
      if (phoneReg.test(phoneString)) {
        data["invitee_phone"] = phoneString;
      } else {
        setError("Email or mobile number is required");
        return;
      }
    }
    if (user.email) {
      data["user_email"] = user.email;
    }
    setInviting(Status.REQUEST);
    userService
      .invite(data)
      .then(() => {
        setInviting(Status.SUCCESS);
        setTimeout(() => {
          setEmail("");
          setInviting(Status.NONE);
        }, 2000);
      })
      .catch(() => {
        setInviting(Status.FAILURE);
        setTimeout(() => {
          setInviting(Status.NONE);
        }, 2000);
      });
  };

  return (
    <S.Footer>
      <div className="container-fluid">
        <Row className="footer-wrapper">
          <div className="col-md-4">
            <div className="download-app">
              <Typos.HF4 className="text-white text-center sec-title" style={{marginBottom: "30px"}}>
                DOWNLOAD THE APP
              </Typos.HF4>
              <div style={{ display: "flex", marginTop: "0", flexWrap:"wrap",  rowGap: "10px", justifyContent: "center", alignItems: "center",  columnGap: "5px"  }}>
                <div className="">
                  <a href="https://apps.apple.com/us/app/the-gift-guide/id6444087494" target="_blank"><img src={Images.AppleBadge} alt="" width="120px" /></a>
                </div>
                <div className="">
                <a href="https://blog.thegiftguide.com/https-blog-thegiftguide-com-sign-up-install-add-to-the-gift-guide-button-mobile-android/" target="_blank"><img src={Images.GoogleBadge} alt="" width="120px" /></a>
                </div>
                <div className="">
                <a href="https://chromewebstore.google.com/detail/add-to-the-gift-guide-but/kpgjgoiolicpejbbolalnfmdahaeoggg?hl=en&pli=1" target="_blank"><img src={Images.ChromBadge} alt="" width="120px" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
              <div  style={{ marginTop: "0", display: "flex", flexDirection: "column", alignContent: "center", placeContent: "center" }}>
              <Typos.HF4 className="text-white tablet-text-center sec-title guide text-center invite-friends" style={{marginBottom: "30px"}}>
                INVITE FRIENDS
              </Typos.HF4>
                <S.Form onSubmit={inviteFriend} className="my-auto d-flex" style={{ marginTop: "0", display: "flex", alignContent: "center", placeContent: "center" }}>
                  <input
                    type="text"
                    style={{ maxWidth: "250px", width: "100%", flex: 1 }}
                    className={error ? "has-error" : ""}
                    value={email}
                    onChange={changeEmail}
                    placeholder="Enter email or mobile #"
                  />
                  <Buttons.Submit
                    theme="light"
                    status={inviting}
                    title={INVITE_BUTTON}
                    style={{
                      border: "1px solid #fff",
                      background: "#000",
                      color: "#fff",
                      maxWidth: "75px",
                      width: "100%",
                      marginLeft: "5px",
                    }}
                  />
                </S.Form>
                <S.FormDesc className="form-message" >
                  <div className="message-wrapper">
                    {inviting === Status.SUCCESS && (
                      <Typos.Success className="text-white">
                        Your invite has been sent!
                      </Typos.Success>
                    )}
                    {inviting === Status.FAILURE && (
                      <Typos.Error className="text-red">
                        This user is already on The Gift Guide.{" "}
                        <Link
                          to="/users"
                          className="text-danger border-bottom border-danger"
                        >
                          Search to follow them.
                        </Link>
                      </Typos.Error>
                    )}
                    {error && (
                      <Typos.Error className="has-error">{error}</Typos.Error>
                    )}
                  </div>
                </S.FormDesc>
              </div>
          </div>
          <div className="col-md-4" style={{display:"flex", justifyContent:"center"}}>
            <S.SocialIcons>
            <Typos.HF4 className="text-white tablet-text-center sec-title" style={{marginBottom: "30px"}}>
              CONNECT WITH US
            </Typos.HF4>
            <div className="d-flex justify-content-end social-icons">
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.PINTEREST}
              >
                <img src={Images.whitePinterest} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.INSTAGRAM}
              >
                <img src={Images.whiteInstagram} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.FACEBOOK}
              >
                <img src={Images.whiteFacebook} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.TWITTER}
              >
                <img src={Images.whiteTwitter} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.YOUTUBE}
              >
                <img src={Images.whiteYoutube} alt="" />
              </S.Icon>
              </div>
            </S.SocialIcons>
          </div>

          <div className="col-md-4 text-center">
            <S.SiteLinks className="footer-links">
              <S.SiteLink as="a" onClick={openContact}>
                <Typos.H8 className="text-white">CONTACT</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.FEEDBACK}>
                <Typos.H8 className="text-white">FEEDBACK</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.ABOUT}>
                <Typos.H8 className="text-white">ABOUT</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.PRIVACY_POLICY}>
                <Typos.H8 className="text-white">TERMS/ PRIVACY</Typos.H8>
              </S.SiteLink>
            </S.SiteLinks>
          </div>
          <div className="col-md-4 text-center">
          </div>
          <div className="col-md-4 text-center" style={{display:"flex", justifyContent:"center"}}>
            <Typos.P2 className="text-white text-right the-guide">
              THE GIFT GUIDE® is a registered trademark of Gift Guide LLC
            </Typos.P2>
          </div>
        </Row>
      </div>
    </S.Footer>
  );
};

export default Footer;
