import environment from "src/environments/environment";

export const APP_LINK = environment.APPLINK;
export const API_BASE_URL = environment.SERVER;

export const Status = {
  NONE: 'NONE',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};