const routes =  {
    ABOUT :  "/about",
    PRIVACY_POLICY :  "/terms",
    ERROR :  '/error',
    FEEDBACK :  "/feedback",
    CONTACT :  "/contact",

    VERIFICATION :  "/verification",

    DASHBOARD :  "/my-dashboard",
    ACCOUNT :  "/my-dashboard",
    ACCOUNT_SETTING :  "/account-settings",
    UPDATE_PROFILE :  '/update-profile',

    WISH_LIST :  "/wish-list",
    ADD_WISH_ITEM :  "/add-to-wish-list",
    WISH_ITEM :  "/wish-list/:id",
    OTHER_WISH_ITEM :  "/wish-item/:id",
    OTHER_WISH_LIST :  "/wish-item/",

    FRIENDS :  "/my-friends",
    USER_DETAIL :  "/profile/:id",
    USER_PROFILE :  "/users/:id",
    ALL_USERS :  "/users",

    ADD_DATE :  '/add-your-occasions',

    CALENDAR :  "/calendar",

    GIFTGUIDES :  "/giftguides",
    GIFTITEM :  "/giftguides/:id",

    BLOG :  "/blog",
    BLOG_DETAIL :  '/blog/:id',

    SEARCH :  "/search",

    PROFILE :  "/profile",

    CREATE_ACCOUNT :  "/create-account",
    PROFILE_PREFERENCE :  "/profile-preferences",
    NOTIFICATION_PREFERENCE :  "/notification-preferences",
    INVITE :  "/invite",

    LOG_IN :  "/log-in",
    SIGN_UP :  "/sign-up",
    CONTACT_US :  "/contact-us",

    PLUGIN :  "/plugin",
    ADD_WISH_ITEM_WIDGET :  '/add-wish-item_v2',
    VERIFY_EMAIL :  "/verify-email",
    REQUEST_VERIFY_EMAIL :  "/verify-email-request",

    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
};

export default routes;
export const SIGN_PAGES = [
    routes.LOG_IN,
    routes.FORGOT_PASSWORD,
    routes.RESET_PASSWORD,
    routes.VERIFY_EMAIL,
    routes.REQUEST_VERIFY_EMAIL,
    routes.CREATE_ACCOUNT,
];